import { gql } from '@apollo/client';

export const QUERY_GET_OBJECTS = gql`
    query getObjects {
        getObjects {
            id
            name
            description
            enabled
            createdBy {
                id
                name
            }
            updatedBy {
                id
                name
            }
            objectProperties {
                id
                name
                enabled
                isRequired
                isDisplayable
                dataType
                order
                createdAt
                createdBy {
                    id
                    name
                }
                listType {
                    id
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const QUERY_FIND_OBJECT_PROPERTIES = gql`
    query FindObjectProperty($data: FindObjectPropertiesInput!) {
        findObjectProperty(data: $data) {
            id
            name
            enabled
            isRequired
            isDisplayable
            dataType
            order
            createdAt
            objectDefinition {
                id
                name
            }
            createdBy {
                id
                name
            }
            listType {
                id
            }
        }
    }
`;
