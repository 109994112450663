import { CustomObjectProperty, IObjectRaw } from '../CustomObjects/types';
import { ITenant } from '../tenants/types';

export type GetLists = {
    getLists: ListObject[];
};

export type RegisterList = {
    registerList: ListObject;
};

export type UpdateList = {
    updateList: ListObject;
};

export type RegisterListVariables = {
    data: {
        listName: string;
        origin: IssuerEnum;
        dataType: listDataTypeEnum;
        enabled: boolean;
        description: string;
        objectDefinitionId?: number;
        tenantId?: number;
        listLinkedTenants?: number[];
        listLinkedUsers?: number[];
    };
};

export type UpdateListVariables = {
    data: Partial<RegisterListVariables['data']> & {
        id: number;
    };
};

export type FindListValues = {
    findListValues: ListValue[];
};

export type FindListValuesVariables = {
    data: {
        listId?: number;
        ids?: number[];
        listIds?: number[];
        origin?: IssuerEnum;
        value?: string;
    };
};

export type CreateListValue = {
    createListValue: ListValue;
};

export type CreateListValueVariables = {
    data: {
        enabled: boolean;
        listId: number;
        listLinkedTenants?: number[];
        listLinkedUsers?: number[];
        dynamicObjectValue?: number;
        order?: number;
        value: string;
    };
};

export type UpdateListValue = {
    updateListValue: ListValue;
};

export type UpdateListValueVariables = {
    data: Partial<CreateListValueVariables['data']> & {
        id: number;
    };
};

export type ListObject = {
    id: string;
    listName: string;
    origin: IssuerEnum;
    enabled: boolean;
    dataType: listDataTypeEnum;
    description: string;
    objectDefinitionId?: string;
    objectDefinition?: Pick<IObjectRaw, 'id' | 'name'>;
    createdAt: string;
    updatedAt: string;
};

export type ListValue = {
    id: string;
    value: string;
    dynamicObjectValue?: {
        id: string;
        objectDefinition: Pick<IObjectRaw, 'id' | 'name'>;
        objectValues: {
            tag: string;
            value: string;
            objectProperty: Partial<CustomObjectProperty>;
        }[];
    };
    linkedTenant: Pick<ITenant, 'id' | 'name'>[];
    order: number;
    listId: Pick<ListObject, 'id'>;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
    isNew?: boolean;
};

export const ISSUER_LIST = {
    BackOffice: 'BackOffice',
    Saas: 'Saas'
} as const;

export type IssuerEnum = keyof typeof ISSUER_LIST;

export const LIST_DATA_TYPE = {
    User: 'User',
    Value: 'Value',
    Object: 'Object'
} as const;

export const LIST_DATA_TYPE_TRANSLATION = {
    User: 'User List',
    Value: 'Primitive list',
    Object: 'Object List'
} as const;

export type listDataTypeEnum = keyof typeof LIST_DATA_TYPE;

export type ListForm = {
    listName: string;
    description: string;
    backofficeManaged: boolean;
    listType: 'User' | 'Value' | 'Object' | '';
    objectDefinitionId?: string;
    enabled: boolean;
};

export type RegisterOrupdateDynamicObject = {
    registerOrUpdateDynamicObject: {
        id: string;
        objectDefinition: Pick<IObjectRaw, 'id' | 'name'>;
        objectValues: {
            tag: string;
            value: string;
            objectProperty: Pick<CustomObjectProperty, 'id' | 'name'>;
        }[];
    };
};
export type PropertyValuePayload = {
    propertyId: number;
    value: string;
};

export type RegisterOrUpdateDynamicObjectsWithValuesPayloadData = {
    id?: number;
    objectDefinitionId: number;
    values: PropertyValuePayload[];
};

export type RegisterOrUpdateDynamicObjectsWithValuesPayload = {
    data: RegisterOrUpdateDynamicObjectsWithValuesPayloadData;
};
