/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { HighlightOff, InsertDriveFileOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import UTC from 'dayjs/plugin/utc';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { CustomObjectProperty } from 'views/backoffice/CustomObjects/types';
import { UploadFileButton } from 'ui-component/UploadFileButton';
import GridWithInlineEdit from 'ui-component/grids/GridWithInlineEdit';
import { ListObject, ListValue } from '../types';
import { StripedDataGrid } from './List';
import { CustomNoRowsOverlay } from 'ui-component/CustomNoRowsOverlay';
import { downloadImportListValuesTemplate } from 'utils/fileHelpers';
import { mapAndValidateImportedValues, mapImportedValueToPreview, mapObjectPropertiesWithExcelKeys } from '../utils';

dayjs.extend(CustomParseFormat);
dayjs.extend(UTC);

export type ImportFormDialogProps = {
    open: boolean;
    onClose: () => void;
    columns: GridColumns;
    isListObject: boolean;
    onSubmitPrimitive: (payload: Pick<ListValue, 'enabled' | 'value' | 'order'>[]) => Promise<void>;
    onSubmitObject: (data: any) => Promise<void>;
    selectedRow?: ListObject;
    objectProperties?: CustomObjectProperty[];
};

export const ImportFormDialog = ({
    open,
    onClose,
    columns,
    isListObject,
    onSubmitPrimitive,
    onSubmitObject,
    selectedRow,
    objectProperties
}: ImportFormDialogProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const listRef = React.useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<any[]>([]);
    const [rootFile, setRootFile] = useState<File>();

    const handleClose = () => {
        onClose();
        setRows([]);
        setRootFile(undefined);
    };

    const generateRowsToPreview = (rowsFromExcel: any[]) => {
        const rowsMapped = rowsFromExcel.map((rowFromExcel: any, index: number) => {
            const rowMapped = {
                createdAt: dayjs.utc().format(),
                enabled: true,
                isNew: true,
                id: index,
                linkedTenant: [],
                userValue: null,
                order: index + 1,
                listId: {
                    id: selectedRow?.id
                },
                updatedAt: dayjs.utc().format(),
                value: isListObject ? '' : rowFromExcel,
                dynamicObjectValue: isListObject
                    ? {
                          id: index,
                          objectValues: rowFromExcel
                              .filter((item: any) => item.value)
                              .map((item: any) => ({
                                  tag: '',
                                  value: mapImportedValueToPreview(item.value, item.dataType),
                                  objectProperty: {
                                      id: item.id,
                                      name: item.name,
                                      isDisplayable: item.isDisplayable,
                                      order: item.order,
                                      dataType: item.dataType
                                  }
                              })),
                          objectDefinition: selectedRow?.objectDefinition
                      }
                    : null
            };
            return rowMapped;
        });

        return rowsMapped.filter((item: any) => (isListObject ? true : item.value && item.value.length > 0));
    };

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
        onDrop: (acceptedFiles) => {
            setRootFile(acceptedFiles[0]);
            const reader = new FileReader();
            reader.onload = (event) => {
                setRows([]);
                const workbook = XLSX.read(event.target?.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const sheetData: any[] = XLSX.utils.sheet_to_json(sheet, { raw: false });
                if (isListObject && objectProperties) {
                    try {
                        const objectPropertiesMapped = mapObjectPropertiesWithExcelKeys(objectProperties, sheetData[0]);
                        const { newValues } = mapAndValidateImportedValues(sheetData, objectPropertiesMapped);
                        const mappedToPreview = generateRowsToPreview(newValues);
                        setRows(mappedToPreview);
                    } catch (error: any) {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Validation Error',
                                subtitle: error.message,
                                variant: 'alert',
                                alert: { color: 'error' },
                                close: true
                            })
                        );
                        setRootFile(undefined);
                    }
                } else {
                    const mappedToPreview = generateRowsToPreview(
                        sheetData.map((sData: { Value: any; [key: string]: any }) => sData.Value)
                    );
                    setRows(mappedToPreview);
                }
            };
            reader.readAsBinaryString(acceptedFiles[0]);
        }
    });

    const handleSubmitForm = async () => {
        setIsLoading(true);
        if (!isListObject) {
            const payload = rows.map((row) => ({
                value: row.value,
                enabled: true,
                order: row.order
            }));
            await onSubmitPrimitive(payload);
        } else {
            const payload = rows.map((row) => ({
                objectDefinitionId: +(selectedRow?.objectDefinition?.id || 0),
                values: row.dynamicObjectValue.objectValues.map((objVal: any) => ({
                    propertyId: +objVal.objectProperty.id,
                    value: String(objVal.value)
                }))
            }));
            await onSubmitObject(payload);
        }
        setRootFile(undefined);
        setRows([]);
        setIsLoading(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl" aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{
                    fontSize: '24px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: 0
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: '500',
                            lineHeight: '32px',
                            color: '#000'
                        }}
                    >
                        Import List Values
                    </Typography>
                </Box>
                <IconButton onClick={handleClose}>
                    <HighlightOff sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={4} sx={{ padding: '2rem 1rem' }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        lineHeight: '19.92px',
                                        color: '#858585'
                                    }}
                                >
                                    Download Template
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '21.98px',
                                        color: '#37326E',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => downloadImportListValuesTemplate(objectProperties ?? [])}
                                >
                                    Template.xls
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: '2rem' }}>
                            <Grid item xs={6}>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        lineHeight: '19.92px',
                                        color: '#858585'
                                    }}
                                >
                                    Upload XLS File
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <UploadFileButton
                                        theme={theme}
                                        rootProps={getRootProps}
                                        variant="outlined"
                                        color="secondary"
                                        label="Upload File"
                                    />
                                    <input accept=".xlsx, .xls" {...getInputProps()} />
                                    {rootFile && (
                                        <Box>
                                            <Button
                                                sx={{
                                                    width: '191px',
                                                    marginTop: '1rem',
                                                    borderStyle: 'dashed',
                                                    padding: '0px',
                                                    borderRadius: '8px'
                                                }}
                                                variant="outlined"
                                                color="secondary"
                                                startIcon={<InsertDriveFileOutlined />}
                                                endIcon={<RemoveCircleOutlineOutlined sx={{ color: '#EF5350' }} />}
                                                onClick={() => {
                                                    setRootFile(undefined);
                                                    setRows([]);
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        overflow: 'hidden',
                                                        alignItems: 'start'
                                                    }}
                                                >
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            maxWidth: '100px',
                                                            textWrap: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            lineHeight: '19.92px'
                                                        }}
                                                    >
                                                        {rootFile.name}
                                                    </Box>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: '300',
                                                            fontSize: '12px',
                                                            lineHeight: '19.92px'
                                                        }}
                                                    >
                                                        {rootFile.size / 1000000} MB
                                                    </Box>
                                                </Box>
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} sx={{ paddingX: '1rem', borderLeft: '1px solid #d9d9d9' }}>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '500',
                                lineHeight: '32px',
                                color: '#000'
                            }}
                        >
                            Preview
                        </Typography>
                        <Box
                            height="400px"
                            id="preview-container"
                            sx={{
                                '& .MuiDataGrid-footerContainer': {
                                    display: 'none'
                                }
                            }}
                        >
                            {rows.length > 0 ? (
                                <GridWithInlineEdit
                                    ref={listRef}
                                    GridComponent={StripedDataGrid}
                                    loading={isLoading}
                                    initialRows={rows || []}
                                    columns={columns.filter((col) => col.field !== 'enabled') || []}
                                    onCreate={async (val) => undefined}
                                    onUpdate={async (val) => undefined}
                                    onUpdateOrder={async (val) => false}
                                    selectedRow={selectedRow}
                                    isPreview
                                />
                            ) : (
                                <CustomNoRowsOverlay message="No data" />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                <Button sx={{ height: '32px' }} variant="outlined" onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    color="secondary"
                    disabled={rows.length === 0}
                    onClick={() => {
                        handleSubmitForm();
                    }}
                    size="small"
                >
                    Import
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
