import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';

// load mock apis
import '_mockApis';
import './globals';
// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import { store } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';
import { setContext } from '@apollo/client/link/context';

// style + assets
import 'assets/scss/style.scss';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE || '');

const cache = new InMemoryCache({
    addTypename: false
});

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const backend_jwt = localStorage.getItem('backend_jwt');
    const authorization = localStorage.getItem('serviceToken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            backend_jwt,
            authorization
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache
});

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <ApolloProvider client={client}>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ApolloProvider>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
