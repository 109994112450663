import { DataGridPro, GridActionsCellItem, gridClasses } from '@mui/x-data-grid-pro';
import { GridLevel } from 'ui-component/grids/GridWithLevels/types';
import { CustomToolbar } from 'ui-component/grids/GridWithLevels/components';
import { Edit } from '@mui/icons-material';
import { alpha, styled } from '@mui/material';

const ODD_OPACITY = 0.2;

export type ListProps = {
    loading: boolean;
    level?: GridLevel;
};

export const List = ({ loading, level }: ListProps) => {
    if (!level) return <></>;

    const internalRowEdit = level.level === 2;
    const { columns, filteredRows } = level;

    let allColumns = [...(columns || [])];

    if (internalRowEdit) {
        allColumns = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width: 70,
                align: 'left',
                cellClassName: 'actions',
                renderCell: ({ id, row }) => [<GridActionsCellItem icon={<Edit />} label="Edit" className="textPrimary" color="inherit" />]
            },
            ...allColumns
        ];
    }

    return (
        <StripedDataGrid
            loading={loading}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            columns={allColumns}
            rows={filteredRows || []}
            editMode="row"
            components={{
                Toolbar: CustomToolbar
            }}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            disableSelectionOnClick
            pagination
            checkboxSelection
            rowReordering
        />
    );
};

// TODO: change this component to the new one in ui-component/grids
export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        },
        '&.Mui-selected': {
            backgroundColor: '#203461',
            color: '#FFFFFF !important',
            '& svg, & .text-secondary': {
                color: '#FFFFFF !important',
                backgroundColor: 'transparent !important'
            },
            '&:hover, &.Mui-hovered': {
                backgroundColor: '#203461',
                color: '#FFFFFF !important',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity)
                }
            }
        }
    },
    [`& .${gridClasses.row}.odd`]: {
        '&.Mui-selected': {
            backgroundColor: '#203461',
            color: '#FFFFFF',
            '& svg, & .text-secondary': {
                color: '#FFFFFF !important',
                backgroundColor: 'transparent !important'
            },
            '&:hover, &.Mui-hovered': {
                backgroundColor: '#203461',
                color: '#FFFFFF',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity)
                }
            }
        }
    }
}));
