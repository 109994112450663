import { gql } from '@apollo/client';

export const MUTATION_REGISTER_LIST = gql`
    mutation RegisterList($data: CreateListsInput!) {
        registerList(data: $data) {
            id
            listName
            origin
            enabled
            dataType
            description
            objectDefinition {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const MUTATION_UPDATE_LIST = gql`
    mutation UpdateList($data: UpdateListsInput!) {
        updateList(data: $data) {
            id
            listName
            origin
            enabled
            dataType
            description
            objectDefinition {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;

export const MUTATION_CREATE_LIST_VALUE = gql`
    mutation CreateListValue($data: CreateListsValuesInput!) {
        createListValue(data: $data) {
            createdAt
            enabled
            id
            linkedTenant {
                id
                name
            }
            order
            updatedAt
            value
            dynamicObjectValue {
                id
                objectValues {
                    tag
                    value
                }
                objectDefinition {
                    id
                    name
                }
            }
        }
    }
`;

export const MUTATION_UPDATE_LIST_VALUE = gql`
    mutation UpdateListValue($data: UpdateListsValuesInput!) {
        updateListValue(data: $data) {
            createdAt
            enabled
            id
            linkedTenant {
                id
                name
            }
            order
            updatedAt
            value
            dynamicObjectValue {
                id
                objectValues {
                    tag
                    value
                }
                objectDefinition {
                    id
                    name
                }
            }
        }
    }
`;
