import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, HighlightOff } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PropertyValuePayload, RegisterOrUpdateDynamicObjectsWithValuesPayloadData } from '../types';
import { PanelFieldSectionInline } from 'ui-component/forms/PanelFieldSectionInline';
import { CustomObjectProperty } from 'views/backoffice/CustomObjects/types';
import NumberFormat from 'react-number-format';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export type FormDialogObjectPropertiesProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: any) => Promise<void>;
    initialData?: any;
    properties: CustomObjectProperty[];
};

export const FormDialogObjectProperties = ({ open, onClose, onSubmit, initialData, properties }: FormDialogObjectPropertiesProps) => {
    const theme = useTheme();
    const isEdit = !!initialData;
    const [isLoading, setIsLoading] = useState(false);

    let formSchemaObject: { [key: string]: any } = {};
    properties.forEach((property) => {
        if (property.isRequired) {
            formSchemaObject = {
                ...formSchemaObject,
                [property.name]: yup.string().trim().required()
            };
        }
    });

    const formSchema = yup.object({ ...formSchemaObject } as Record<any, any>).required();

    const {
        control,
        setValue,
        reset,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<{ [key: string]: any }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange'
    });

    const handleSubmitForm = async (data: any) => {
        const valuesMapped: PropertyValuePayload[] = [];

        properties.forEach((property) => {
            if (data[property.name] !== null && data[property.name] !== undefined) {
                valuesMapped.push({
                    propertyId: +property.id,
                    value: data[property.name]
                });
            }
        });
        const payload: RegisterOrUpdateDynamicObjectsWithValuesPayloadData = {
            objectDefinitionId: +(properties[0].objectDefinition.id || 0),
            values: valuesMapped
        };
        setIsLoading(true);
        await onSubmit(payload);
        onClose();
        setIsLoading(false);
    };

    useEffect(() => {
        if (open && initialData) {
            const { rowData } = initialData;
            reset(rowData);
        } else {
            reset({});
        }
    }, [initialData, open, reset]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle
                id="form-dialog-title"
                sx={{
                    fontSize: '24px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingBottom: 0
                }}
            >
                <Box>
                    {isEdit ? 'Edit' : 'Create'} Value
                    <Typography>View all object properties here.</Typography>
                </Box>
                <IconButton onClick={onClose}>
                    <HighlightOff sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    {properties.map((item, index) => (
                        <>
                            {(item.dataType === 'string' || item.dataType === 'url') && (
                                <PanelFieldSectionInline title={item.name} labelColumns={2} inputColumns={10}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                sx={{
                                                    '& fieldset': {
                                                        border: 'none !important'
                                                    },
                                                    '& input, & div': {
                                                        borderRadius: '12px !important'
                                                    }
                                                }}
                                                variant="outlined"
                                                error={!!errors[item.name]}
                                                helperText={errors[item.name]?.message}
                                                fullWidth
                                                autoFocus={index === 0}
                                                {...field}
                                            />
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}
                            {item.dataType === 'number' && (
                                <PanelFieldSectionInline title={item.name} labelColumns={2} inputColumns={10}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <NumberFormat
                                                autoFocus={index === 0}
                                                customInput={TextField}
                                                fixedDecimalScale={false}
                                                thousandSeparator=""
                                                decimalScale={0}
                                                variant="outlined"
                                                error={!!errors[item.name]}
                                                helperText={errors[item.name]?.message}
                                                fullWidth
                                                {...field}
                                                sx={{
                                                    '& fieldset': {
                                                        border: 'none !important'
                                                    },
                                                    '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                                        '&::placeholder': { color: 'transparent !important' }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}
                            {item.dataType === 'date' && (
                                <PanelFieldSectionInline title={item.name} labelColumns={2} inputColumns={10}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value: datePicker, ref } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(newValue) => {
                                                        if (newValue === null) {
                                                            setValue(item.name, null, { shouldValidate: true });
                                                        } else {
                                                            onChange(newValue);
                                                        }
                                                    }}
                                                    inputRef={ref}
                                                    value={datePicker || null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                '& fieldset': {
                                                                    border: 'none !important'
                                                                },
                                                                '& :focus-visible, :focus, :active, .Mui-focused': {
                                                                    '&::placeholder': { color: 'transparent !important' }
                                                                }
                                                            }}
                                                            fullWidth
                                                            autoFocus={index === 0}
                                                            {...params}
                                                            error={!!errors[item.name]}
                                                            helperText={errors[item.name]?.message}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}
                            {item.dataType === 'datetime' && (
                                <PanelFieldSectionInline title={item.name} labelColumns={2} inputColumns={10}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value: datePicker, ref } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    onChange={(newValue) => {
                                                        if (newValue === null) {
                                                            setValue(item.name, null, { shouldValidate: true });
                                                        } else {
                                                            onChange(newValue);
                                                        }
                                                    }}
                                                    inputRef={ref}
                                                    value={datePicker || null}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                '& fieldset': {
                                                                    border: 'none !important'
                                                                },
                                                                '& :focus-visible, :focus, :active, .Mui-focused': {
                                                                    '&::placeholder': { color: 'transparent !important' }
                                                                }
                                                            }}
                                                            autoFocus={index === 0}
                                                            fullWidth
                                                            {...params}
                                                            error={!!errors[item.name]}
                                                            helperText={errors[item.name]?.message}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}
                            {item.dataType === 'checkbox' && (
                                <Grid item xs={12} sx={{ py: '10px' }}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <>
                                                <FormGroup style={{ padding: 10 }}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={2}>
                                                            <Typography style={{ fontWeight: 600, fontSize: '13px', paddingLeft: '1rem' }}>
                                                                {item.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={10} sx={{ display: 'flex', justifyContent: 'start' }}>
                                                            <Grid xs={6} sx={{ textAlign: 'left' }}>
                                                                <Checkbox
                                                                    defaultChecked
                                                                    checked={!!field.value && field.value === 'true'}
                                                                    onChange={(event: any) => {
                                                                        setValue(item.name, String(event.target.checked));
                                                                    }}
                                                                    sx={{
                                                                        padding: 0,
                                                                        color: theme.palette?.primary[400],
                                                                        '&.Mui-checked': { color: theme.palette?.secondary.main },
                                                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                                                    }}
                                                                    icon={<CheckBoxOutlineBlankOutlined />}
                                                                    checkedIcon={<CheckBoxOutlined />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </FormGroup>
                                                {errors[item.name] && (
                                                    <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                                        {errors[item.name]?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                            )}
                        </>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                <Button sx={{ color: theme.palette.error.dark }} onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    color="secondary"
                    disabled={!isValid}
                    onClick={handleSubmit(handleSubmitForm)}
                    size="small"
                >
                    {isEdit ? 'Save' : 'Create'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
